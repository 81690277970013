import React from "react"
import styled from "styled-components"
// components
import Layout from "../../components/layout"
import TitleLeft from "../../components/molecules/title_left"
import Paragraph from "../../components/atoms/paragraph"
import SEO from "../../components/seo"

const StyledWork = styled.div`
  background: #e8ecef;
  display: grid;
  grid-column-end: -1;
  grid-column-start: 1;
  grid-row-gap: 10px;
  grid-template-columns: inherit;
  grid-template-rows: auto auto auto auto auto;
  padding: 15px 0;
`

const OuterWrapper = styled.div`
  background: #ffffff;
  grid-column-end: ${props => props.gridColumnEnd};
  grid-column-start: ${props => props.gridColumnStart};

  @media (min-width: 960px) {
    display: flex;
    justify-content: ${props => props.justifyContent};
  }
`

const InnerWrapper = styled.div`
  box-sizing: border-box;
  min-height: 200px;
  padding: 20px;
  width: 930px;

  @media (max-width: 959px) {
    margin-left: ${props => props.marginLeft};
    margin-right: ${props => props.marginRight};
    width: auto;
  }
`

const Work = ({ location }) => {
  const exterior = "建物の状態に応じた適切な周期で修繕工事が必要になります。\nマルトモでは足場を行い、屋上、バルコニー防水や外壁、鉄部塗装などをまとめて行います。マンション、商業施設、戸建てあらゆる建物の修繕工事お任せください。"
  const waterproofing = "建物の屋上・ベランダから降雨水がしみこむのを防ぐために、下地の状態に適した部材・工法で防水処理を施します。"
  const sealing = "窓廻り・目地・各種取り合い部に充填されているやわらかいシーリング材が経年等で劣化し硬化したものを撤去して各種シーリング材で再充填します。"
  const painting = "適切な下地処理を施したあと、雨や紫外線から外壁等を守るため、又各種仕上げ材でキレイにお化粧し保護します。"
  const other = "金物工事、手摺り、避難ハッチなどの取替。外構工事、門扉やフェンスなどのエクステリア工事なども行います。"
  return (
    <Layout
      location={location}
      showContact={true}
    >
      <StyledWork>
        <OuterWrapper
          gridColumnEnd="-2"
          gridColumnStart="1"
          justifyContent="flex-end"
        >
          <InnerWrapper marginLeft="15px">
            <TitleLeft
              level={2}
              fontSize="large"
              heading="外装修繕工事"
            />
            <Paragraph
              fontSize="15px"
              whiteSpace="pre-wrap"
              paragraph={exterior}
            />
          </InnerWrapper>
        </OuterWrapper>
        <OuterWrapper
          gridColumnEnd="-1"
          gridColumnStart="2"
        >
          <InnerWrapper marginRight="15px">
            <TitleLeft
              level={2}
              fontSize="large"
              heading="防水工事"
            />
            <Paragraph
              fontSize="15px"
              whiteSpace="pre-wrap"
              paragraph={waterproofing}
            />
          </InnerWrapper>
        </OuterWrapper>
        <OuterWrapper
          gridColumnEnd="-2"
          gridColumnStart="1"
          justifyContent="flex-end"
        >
          <InnerWrapper marginLeft="15px">
            <TitleLeft
              level={2}
              fontSize="large"
              heading="シーリング工事"
            />
            <Paragraph
              fontSize="15px"
              whiteSpace="pre-wrap"
              paragraph={sealing}
            />
          </InnerWrapper>
        </OuterWrapper>
        <OuterWrapper
          gridColumnEnd="-1"
          gridColumnStart="2"
        >
          <InnerWrapper marginRight="15px">
            <TitleLeft
              level={2}
              fontSize="large"
              heading="塗装工事"
            />
            <Paragraph
              fontSize="15px"
              whiteSpace="pre-wrap"
              paragraph={painting}
            />
          </InnerWrapper>
        </OuterWrapper>
        <OuterWrapper
          gridColumnEnd="-2"
          gridColumnStart="1"
          justifyContent="flex-end"
        >
          <InnerWrapper marginLeft="15px">
            <TitleLeft
              level={2}
              fontSize="large"
              heading="その他"
            />
            <Paragraph
              fontSize="15px"
              whiteSpace="pre-wrap"
              paragraph={other}
            />
          </InnerWrapper>
        </OuterWrapper>
      </StyledWork>
    </Layout>
  )
}

export default Work

export const Head = ({ location }) => (
  <SEO
    title="工事内容"
    description="株式会社マルトモの工事内容ページです。"
    pathname={location.pathname}
  />
)
